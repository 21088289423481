
button {
  padding: 0;
  border-width: 2px;
  border-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.pc-mode button {
  width: 288px;
  height: 73px;
  border-radius: 43px;
  font-size: 26px;
  line-height: 33px;
}

.mobile-mode button {
  width: 208px;
  height: 58px;
  border-radius: 30px;
  font-size: 20px;
  line-height: 25px;
}

button:not(header) {
  color: white;
  background-color: black;
}

button.header {
  color: #333333;
  background-color: transparent;
}

button:hover {
  color: white;
  background-color: black;
  cursor: pointer;
}

button:not(.channel) span {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 500;
}

button.channel span {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 600;
  margin-left: 10px;
}