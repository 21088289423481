@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-Black.woff);
    font-weight: 900;
}

@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-BlackItalic.woff);
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-Bold.woff);
    font-weight: 700;
}

@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-BoldItalic.woff);
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-ExtraLight.woff);
    font-weight: 200;
}

@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-ExtraLightItalic.woff);
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-Italic.woff);
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-Light.woff);
    font-weight: 300;
}

@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-LightItalic.woff);
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-Medium.woff);
    font-weight: 500;
}

@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-MediumItalic.woff);
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-Regular.woff);
    font-weight: 400;
}

@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-SemiBold.woff);
    font-weight: 600;
}

@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-SemiBoldItalic.woff);
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-Thin.woff);
    font-weight: 100;
}

@font-face {
    font-family: Livvic;
    src: url(/public/font/Livvic-ThinItalic.woff);
    font-weight: 100;
    font-style: italic;
}