
.panel.gallery {
  background: white;
  opacity: 0;
  margin-top: 50px;
}

.panel.gallery.show {
  opacity: 1;
  margin-top: 0px;
  transition:
    opacity 2s,
    margin-top 2s;
}

.panel.gallery .wrap {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.panel.gallery .wrap.wrap-left {
    justify-content: flex-start;
    padding-left:8%;
}

.panel.gallery .wrap.wrap-right {
    justify-content: flex-end;
    padding-right:8%;
}
