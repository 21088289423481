
.page-header {
  display:flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 1000;
  top:0;
  left:0;
  right:0;
}

.pc-mode.page-header{
  height: 102px;
  padding-left: 78px;
  padding-right: 10px;
}

.mobile-mode.page-header{
  height: 60px;
  padding-left: 30px;
  padding-right: 10px;
}

.pc-mode.page-header .logo {
  display: flex;
  height: 70px;
}

.mobile-mode.page-header .logo {
  display: flex;
  height: 40px;
}

.page-header .logo1 {
  height: 100%;
  object-fit: contain;
}

.page-header.pc-mode .logo2 {
  margin-left: 7px;
  height: 33%;
  margin-top: 5%;
  object-fit: contain;
}

.page-header.mobile-mode .logo2{
  margin-left: 4px;
  height: 33%;
  margin-top: 5%;
  object-fit: contain;
}

.page-body {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.pc-mode .panel:not(.bottom):not(.slide-panel) {
  display:flex;
  width: 100%;
  height: 1080px;
}

.mobile-mode .panel:not(.bottom):not(.slide-panel) {
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 930px;
}
