
.panel.top {
    display: flex;;
    justify-content: space-around;
    align-items: center;
    background: linear-gradient(143.23deg, #EBECFF 0%, #F4EBFF 49.51%, #FEF4FA 99.03%);
    z-index: 1;
}

.panel.top > .top-left {
    width: 736px;
    display: flex;
    flex-direction: column;
}

.mobile-mode .panel.top .top-left {
    align-items: center;
    margin-top: 88px;
    width: 90%;
}

.mobile-mode .panel.top .content {
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: center;
    width: 95%;
}

.pc-mode .panel.top .content {
    text-align: left;
    margin-top: 32px;
    margin-bottom: 108px;
}

.pc-mode .panel.top .top-right {
    height: 700px;
    object-fit: contain;
    border-radius: 40px;
}

.mobile-mode .panel.top .top-right {
    margin-top: 10px;
    width: 70%;
    object-fit: contain;
    border-radius: 12% / 6%;
}
