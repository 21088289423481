
.photo-text {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.photo-text.left-text {
    align-items: flex-start;
}

.photo-text.right-text {
    align-items: flex-end;
}


.pc-mode .photo-text .content {
    margin-top: 33px;
}

.mobile-mode .photo-text .title {
    margin-bottom: 12px;
}

.photo-text.left-text > span {
    width: 85%;
}

.photo-text.right-text > span {
    width: 85%;
}

.photo-text.middle-text > span.title {
    width: 90%;
}

.photo-text.middle-text > span.content {
    width: 75%;
}