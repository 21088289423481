

.gallery-image {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pc-mode .gallery-image {
    border-radius: 45px;
    width: 85%;
    object-fit: contain;
}

.mobile-mode .gallery-image {
    border-radius: 45px;
    width: 75%;
    object-fit: contain;
}
