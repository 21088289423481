
.panel.bottom {
    display: flex;
    width: 100%;
    background: linear-gradient(180deg, #E0F0FF 0%, #F0EEFF 50%, #F8F0FF 100%);
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.pc-mode .panel.bottom {
    height: 1280px;
}

.mobile-mode .panel.bottom {
    padding-top: 50px;
    padding-bottom: 50px;
}

.panel.bottom .bottom-header {
    color: #7F7EA8;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.pc-mode .panel.bottom .bottom-header span {
    font-size: 80px;
}

.mobile-mode .panel.bottom .bottom-header span {
    font-size: 28px;
}

.panel.bottom .bottom-body {
    width: 100%;
}

.pc-mode .panel.bottom .bottom-body {
    width: 100%;
}
.mobile-mode .panel.bottom .bottom-body {
    width: 100%;
    display: flex;;
    flex-direction: column;
    align-items: center;
}

.panel.bottom .bottom-body .cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.pc-mode .panel.bottom .bottom-body .cell {
    height: 450px;
    padding: 10px;
}

.panel.bottom .bottom-body hr {
    width: 450px;
    color:transparent;
    border: 1px solid #B4C0EE;
}

.mobile-mode .panel.bottom hr {
    max-width: 75%;
    margin-top: 20px;
}

.panel.bottom .bottom-body .cell-title {
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-weight: 700;
    text-align: center;

    color: #7F7EA8;
}

.pc-mode .panel.bottom .bottom-body .cell-title {
    font-size: 44px;
    height: 52px;
}

.mobile-mode .panel.bottom .bottom-body .cell-title {
    font-size: 20px;
    line-height: 25px;
    height: 52px;
}

.pc-mode .panel.bottom .bottom-body .image {
    width: 200px;
    height: 200px;
    object-fit: contain;
}

.mobile-mode .panel.bottom .bottom-body .image {
    width: 38vw;
    height: 38vw;
    object-fit: contain;
}

.panel.bottom .bottom-body .cell-contents {
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;

    color: #7F7EA8;
}


