
span {
    font-family: 'Livvic';
    font-style: normal;
}

span.title {
    font-weight: 900;
}

span.content {
    font-weight: 500;
}

.pc-mode span.title {
    font-size: 60px;
    line-height: 75px;
}

.mobile-mode span.title {
    font-size: 28px;
    line-height: 35px;
    text-align: center;
}

span.title.black {
    color: #444444;
}

span.title.color {
    color: #7F7EA8;
}

.pc-mode span.content {
    font-size: 26px;
    line-height: 33px;
}

.mobile-mode span.content {
    font-size: 20px;
    line-height: 25x;
}

span.content.black {
    color: #666666;
}

span.content.color {
    color: #9191AC;
}