


.panel.slide-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: linear-gradient(180deg, #F3F4FF 0%, #F9FAFF 50%, #FFFFFF 100%);
}

.pc-mode .panel.slide-panel {
    justify-content: center;
    height: 2160px;
}

.mobile-mode .panel.slide-panel {
    justify-content: space-evenly;
    padding-top: 50px;
    padding-bottom: 50px;
}

.panel.slide-panel .slide-up {
    display: flex;
    height: 1080px;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}


.pc-mode .panel.slide-panel .slide-up-img {
    width: 413px;
    height: 830px;
}

.mobile-mode .panel.slide-panel .slide-up-img {
    width: 75%;
    object-fit: contain;
}

.panel.slide-panel .slide-up-tips {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.pc-mode .panel.slide-panel .slide-down {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 1080px;
}

.mobile-mode .panel.slide-panel .slide-down {
    display: flex;
    flex-direction: column;
}

.panel.slide-panel .slide-down-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pc-mode .panel.slide-panel .tips {
    color: #7F7EA8;
    font-family: 'Livvic';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    margin-top: 100px;
}

.mobile-mode .panel.slide-panel .tips {
    color: #7F7EA8;
    font-family: 'Livvic';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    margin-top: 40px;
}


.panel.slide-panel .slide-item {
    align-items: center;
    display: flex;
    margin: 0;
}

.panel.slide-panel .slide-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 13% / 10%;
}