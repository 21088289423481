
.panel .gallery-background {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.panel .bg-ball {
    position:absolute;
    border-radius: 50%;
    z-index: 0;
}

.panel .bg-text {
    font-weight: 400;
    font-size: 219.7px;
    line-height: 275.73px;
    text-align: center;

    background: linear-gradient(180deg, #DDDFF0 0%, #F8F8FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    mix-blend-mode: multiply;
    opacity: 0.4;
}

.panel .bg-wrap {
    padding-top: 8%;
    display: flex;
    justify-content: center;
}